<template>
  <v-dialog v-model="dialog" max-width="750px">
    <v-card>
      <v-card-title>
        <span class="headline">
          Êtes vous sur de vouloir supprimer {{ title }} ?
        </span>
      </v-card-title>
      <v-card-text>
        {{ description }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="grey darken-2" text
          @click="close"
        >
          Annuler
        </v-btn>
        <v-btn
          color="error" text
          :loading="loading"
          @click="remove"
        >
          Supprimer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const capitalize = (subject) => {
  return subject.charAt(0).toUpperCase() + subject.slice(1)
}

export default {
  props: {
    resource: {
      type: String,
      default: ''
    },
    preventRemove: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'cet objet'
    },
    description: {
      type: String,
      default: ''
    },
    useSpecial: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false,
    dialog: false,
    item: {}
  }),
  methods: {
    open(item) {
      this.item = item
      this.loading = false
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    remove() {
      this.loading = true
      if (this.preventRemove) {
        this.$emit('remove', this.item)
        return
      }
      this.$store.dispatch(
        this.resource + '/remove' + (this.useSpecial ? 'Special' : ''),
        this.item
      ).then(() => {
        this.loading = false
        this.$store.commit('alert/add', {
          color: 'success',
          text: capitalize(this.title) + ' a bien été supprimé'
        })
        this.$emit('finished')
        this.close()
      }).catch(err => {
        console.error(err)
        this.loading = false
        this.$store.commit('alert/add', {
          color: 'error',
          text: 'Une erreur est survenue lors de la suppression de ' + this.title
        })
      })
    }
  }
}
</script>
